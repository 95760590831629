import React from "react";
import {motion} from 'framer-motion';
import { Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "../styling/pages/articlesPage.css";

// import article_img from "../images/1.jpg";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function ScrollToTop() {
  window.scrollTop(0);
}


const ArticlesPage = () => { 
  return (
  <div>
  <Navbar />
  <section className="articles-page">
    <h1>Η σελίδα βρίσκεται υπό κατασκευή...</h1>
    <i><FontAwesomeIcon icon={faGear} size="7x" className='spinner' /></i>

  
  {/* //       <h1 className="articles-header">Οφθαλμολογικά Άρθρα από τον ιατρό Δημήτριο Τριγκούδη</h1>
  //       <div className="articles-container">
  //       <div className="card-column column-0">
  //       <div  className="card card-color-0">
  //       <div className="border"></div>
  //       <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/53148/deathtostock-00.jpg" />
  //       <h1>Hey now, you're an allstar</h1>
  //       <p>Lorem ipsum dolor sit amet dis ornare convallis dapibus dictum maecenas sem imperdiet fames porta habitasse ipsum diam pharetra neque tortor nibh quam porttitor libero eget ante vestibulum</p>
  //       <Link to='/articles/article/1010' onClick={ScrollToTop}>Διαβάστε περισσότερα...</Link>
  //       </div>
      
  //       <div className="card card-color-2">
  //       <div className="border"></div>
  //       <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/53148/deathtostock-02.jpg" />
  //       <h1>Hey now, you're a rock star</h1>
  //       <p>Lorem ipsum dolor sit amet dis ornare convallis dapibus dictum maecenas sem imperdiet fames porta habitasse ipsum diam pharetra neque tortor nibh quam porttitor libero eget ante vestibulum</p>
  //       <Link to='/articles/article/1011' onClick={ScrollToTop}>Διαβάστε περισσότερα...</Link>
  //       </div>
  //   </div>
  //   <div className="card-column column-1">
  //       <div className="card card-color-1">
  //       <div className="border"></div>
  //       <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/53148/deathtostock-01.jpg" />
  //       <h1>Get your game on, go play</h1>
  //       <p>Lorem ipsum dolor sit amet dis ornare convallis dapibus dictum maecenas sem imperdiet fames porta habitasse ipsum diam pharetra neque tortor nibh quam porttitor libero eget ante vestibulum</p>
  //       <Link to='/articles/article/1012' onClick={ScrollToTop}>Διαβάστε περισσότερα...</Link>
  //       </div>
      
  //       <div className="card card-color-3">
  //       <div className="border"></div>
  //       <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/53148/deathtostock-03.jpg" />
  //       <h1>Get the show on, get paid</h1>
  //       <p>Lorem ipsum dolor sit amet dis ornare convallis dapibus dictum maecenas sem imperdiet fames porta habitasse ipsum diam pharetra neque tortor nibh quam porttitor libero eget ante vestibulum</p>
  //       <Link to='/articles/article/1013' onClick={ScrollToTop}>Διαβάστε περισσότερα...</Link>
  //       </div>
  //       </div>
      
  //   </div> */}
       </section>
     <Footer />
   </div>
  

  );
};
  

export default ArticlesPage;
