import React from "react";
import "../styling/components/imageSlider.css";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const OfficeImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideStyles = {
    backgroundImage: `url(${slides[currentIndex]})`,
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const automaticChange = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setTimeout(slides, 2000);
  };

  return (
    <div onLoad={automaticChange} className="sliderStyles">
      <div onClick={goToPrevious}>
        <FontAwesomeIcon className="leftArrowStyles" icon={faArrowLeft} />
      </div>
      <div onClick={goToNext}>
        <FontAwesomeIcon className="rightArrowStyles" icon={faArrowRight} />
      </div>
      <div className="slideStyles" style={slideStyles}></div>
    </div>
  );
};

export default OfficeImageSlider;
