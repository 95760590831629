import React from "react";
import { Link } from "react-router-dom";
import "../styling/pages/homePage.css";
import contact_svg from "../images/contact.svg";
// import bio_img from "../images/personal-image.png";
import bio_img from "../images/IMG-20230617-WA0002.jpg"
import bio_img2 from "../images/bio_img2.png"

import ContactForm from "../components/ContactForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function ScrollToTop() {
  window.scrollTop(0);
}

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <section className="home-page">
        <div
          className="bg-homepage"
          style={{ backgroundImage: `url(${bio_img})` }}
        >
          <div className="box-name">
            <h1>Δημήτριος Τριγκούδης</h1>
          </div>

          <div className="box-doctor">
            <h1>Χειρουργός Οφθαλμίατρος</h1>
          </div>

          <div className="box-city">
            <h1>Θεσσαλονίκη</h1>
          </div>
        </div>
      </section>
      <section className="bio-page">
        <div className="bio-info">
          <h1>Δημήτριος Τριγκούδης</h1>
<div>          <p>
            Ονομάζομαι Δημήτριος Τριγκούδης και εργάζομαι ως Χειρουργός
            Οφθαλμίατρος. Λειτουργώ το δικό μου ιδιωτικό ιατρείο εδώ και 20 χρόνια, το
            οποίο βρίσκεται στην Θεσσαλονίκη. Γεννήθηκα το 1967 σε ένα χωριό του νομού Έβρου, της περιφέρειας Διδυμοτείχου, στους Μεταξάδες, όπου και πέρασα τα παιδικά μου χρόνια.
            {/* Χειρουργώ στην κλινική
            Opthalmica, με την οποία και συνεργάζομαι. */}
             Διαβάστε περισσότερα για
            εμένα πατώντας 
          </p><span><p><Link className="bio-link"  onClick={ScrollToTop}  to="/bio">εδώ</Link></p></span></div>

          <Link className="bio-btn" onClick={ScrollToTop} to="/bio">
            Το βιογραφικό μου
          </Link>
        </div>
        <div>
          <img className="bio-img" src={bio_img2} alt=""></img>
        </div>
      </section>


      <section className="contact-page" id="contact">
        <div className="contact-elements">
          <h1>Συμπληρώστε την παρακάτω φόρμα για να επικοινωνήσετε μαζί μου</h1>
          <ContactForm />
        </div>
        <img className="contact-img" src={contact_svg} alt=""></img>
      </section>
      <Footer />
    </div>
  );
};

export default HomePage;
