import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//pages
import HomePage from "./pages/homePage";
import OfficePage from "./pages/officePage";
import BiographyPage from "./pages/biographyPage";
import ArticlesPage from "./pages/articlesPage";

//styling
import "./styling/main.css";
import "./styling/responsive.css";

import Article1 from "./pages/1010";
import Article2 from "./pages/1011";
import Article3 from "./pages/1012";
import Article4 from "./pages/1013";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/office" element={<OfficePage />}></Route>
          <Route path="/bio" element={<BiographyPage />}></Route>
          <Route path="/articles" element={<ArticlesPage />}></Route>
          <Route path="/articles/article/1010" element={<Article1 />}></Route>
          <Route path="/articles/article/1011" element={<Article2 />}></Route>
          <Route path="/articles/article/1012" element={<Article3 />}></Route>
          <Route path="/articles/article/1013" element={<Article4 />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
