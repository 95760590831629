import React from "react";
import emailjs from "@emailjs/browser";
import "../styling/pages/homePage.css";

const form = document.getElementsByClassName("form");

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_geg3yye",
      "template_5h819se",
      form.current,
      "cQVQCxUUDnaL3GEjD"
    )
    .then(
      (result) => {
        console.log("Message succesfully sent");
        alert("Το μύνημά σας στάλθηκε με επιτυχία!");
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
      }
    );
};

const validateForm = (e) => {
  e.preventDefault();

  const user_name = document.getElementById("full_name").value;
  const user_email = document.getElementById("email").value;
  const phone_number = document.getElementById("number").value;
  const user_message = document.getElementById("message").value;

  //user name validation
  usernameBlankCheck(user_name);

  function usernameBlankCheck(username) {
    if (username === "") {
      document.querySelector(".username-error").style.display = "inline-block";
      document.querySelector(".username-error").innerHTML =
        "Παρακαλώ συμπληρώστε το ονοματεπώνυμό σας!";
    } else {
      document.querySelector(".username-error").style.display = "none";
    }
  }

  // email validation
  emailBlankCheck(user_email);

  function emailBlankCheck(email) {
    if (email === "") {
      document.querySelector(".email-error").style.display = "inline-block";
      document.querySelector(".email-error").innerHTML =
        "Παρακαλώ συμπληρώστε την ηλεκτρονική σας διεύθυνση!";
    } else {
      document.querySelector(".email-error").style.display = "none";
      emailValidationCheck(user_email);
    }
  }

  function emailValidationCheck(email) {
    var validator = require("email-validator");

    if (validator.validate(email)) {
      document.querySelector(".email-error").style.display = "none";
    } else {
      document.querySelector(".email-error").style.display = "inline-block";
      document.querySelector(".email-error").innerHTML =
        "Μη έγκυρη ηλεκτρονική διεύθυνση!";
    }
  }

  //phone number validation

  // Logic:
  // 1. Check if the input field is blank or not. If yes call the second function. Else display an error.
  // 2. The second function is called to check whether the input text includes any letter. If yes then display an error.Else call the third function.
  // 3. The third function is called to check if the input text includes any signs like . / , * / - and so on. If yes then display an error. Else call the fourth function.
  // 4. The fourth function is called to check the length of the input text. If it is 10 digits, then call the fifth function. Else display an error.
  // 5. The fifth function is called to check if all the digits from the input text are the same. If yes then display an error. Else continue to the message validation functionality.

  function phoneNumberBlankCheck(number) {
    if (number === "") {
      document.querySelector(".phoneNumber-error").innerHTML =
        "Παρακαλώ συμπληρώστε το τηλέφωνό σας!";
      document.querySelector(".phoneNumber-error").style.display =
        "inline-block";
    } else if (number !== "") {
      document.querySelector(".phoneNumber-error").style.display = "none";
      phoneNumberCharacterCheck(phone_number);
    }
  }

  phoneNumberBlankCheck(phone_number);

  function phoneNumberCharacterCheck(number) {
    if (
      number.includes(
        "α" ||
          "β" ||
          "γ" ||
          "δ" ||
          "ε" ||
          "ζ" ||
          "η" ||
          "θ" ||
          "ι" ||
          "κ" ||
          "λ" ||
          "μ" ||
          "ν" ||
          "ξ" ||
          "ο" ||
          "π" ||
          "ρ" ||
          "σ" ||
          "τ" ||
          "υ" ||
          "φ" ||
          "χ" ||
          "ψ" ||
          "ω" ||
          "Α" ||
          "Β" ||
          "Γ" ||
          "Δ" ||
          "Ε" ||
          "Ζ" ||
          "Η" ||
          "Θ" ||
          "Ι" ||
          "Κ" ||
          "Λ" ||
          "Μ" ||
          "Ν" ||
          "Ξ" ||
          "Ο" ||
          "Π" ||
          "Ρ" ||
          "Σ" ||
          "Τ" ||
          "Υ" ||
          "Φ" ||
          "Χ" ||
          "Ψ" ||
          "Ω"
      )
    ) {
      document.querySelector(".phoneNumber-error").innerHTML =
        "Ο αριθμός δεν πρέπει να περιέχει οποιοδήποτε γράμμα!";
      document.querySelector(".phoneNumber-error").style.display =
        "inline-block";
    } else {
      document.querySelector(".phoneNumber-error").style.display = "none";
      phoneNumberSignCheck(phone_number);
    }
  }

  function phoneNumberSignCheck(number) {
    if (number.includes("," || "." || "/" || "=" || "-" || "+" || "*")) {
      document.querySelector(".phoneNumber-error").innerHTML =
        "Χαρακτήρες όπως + = . - / , * δεν πρέπει να περιέχονται!";
      document.querySelector(".phoneNumber-error").style.display =
        "inline-block";
    } else {
      document.querySelector(".phoneNumber-error").style.display = "none";
      phoneNumberLengthCheck(phone_number);
    }
  }

  function phoneNumberLengthCheck(number) {
    if (number.length === 10) {
      document.querySelector(".phoneNumber-error").style.display = "none";
      phoneNumberSameCharactersCheck(phone_number);
    } else {
      document.querySelector(".phoneNumber-error").innerHTML =
        "Το πλήθος των ψηφίων πρέπει να είναι 10!";
      document.querySelector(".phoneNumber-error").style.display =
        "inline-block";
    }
  }
  function phoneNumberSameCharactersCheck(number) {
    if (
      number[0] === number[1] &&
      number[1] === number[2] &&
      number[2] === number[3] &&
      number[3] === number[4] &&
      number[4] === number[5] &&
      number[5] === number[6] &&
      number[6] === number[7] &&
      number[7] === number[8] &&
      number[8] === number[9]
    ) {
      document.querySelector(".phoneNumber-error").style.display =
        "inline-block";
      document.querySelector(".phoneNumber-error").innerHTML =
        "Μη έγκυρος αριθμός τηλεφώνου! Όλα τα ψηφία είναι ίδια μεταξύ τους!";
    } else {
      document.querySelector(".phoneNumber-error").style.display = "none";
    }
  }

  // message validation

  messageBlankCheck(user_message);

  function messageBlankCheck(message) {
    if (message === "") {
      document.querySelector(".message-error").style.display = "inline-block";
      document.querySelector(".message-error").innerHTML =
        "Παρακαλώ συμπληρώστε το μύνημά σας!";
    } else if (message !== "") {
      document.querySelector(".message-error").style.display = "none";
      messageLengthCheck(user_message);
    }
  }

  function messageLengthCheck(message) {
    if (message.length >= 500) {
      document.querySelector(".message-error").style.display = "inline-block";
      document.querySelector(".message-error").innerHTML =
        "Το μέγεθος του μυνήματος πρέπει να είναι μέχρι 500 χαρακτήρες!";
    } else {
      document.querySelector(".message-error").style.display = "none";
      validateAllTogether();
    }
  }

  function validateAllTogether() {
    if (
      document.querySelector(".username-error").style.display === "none" &&
      document.querySelector(".email-error").style.display === "none" &&
      document.querySelector(".phoneNumber-error").style.display === "none" &&
      document.querySelector(".message-error").style.display === "none"
    ) {
      sendEmail(e);
    } else {
      alert("Παρακαλώ καταχωρήστε τα αληθή στοιχεία σας!");
    }
  }
};

const ContactForm = () => {
  return (
    <form className="form" ref={form} onSubmit={validateForm}>
      <div className="input1">
        <label htmlFor="full_name">Ονοματεπώνυμο</label>
        <input
          id="full_name"
          type="text"
          placeholder="Το ονοματεπώνυμό σας..."
          name="user_name"
        />
        <small className="username-error"></small>
      </div>

      <div className="input2">
        <label htmlFor="email">Διε/ση Ηλ. Ταχυδρομείου</label>
        <input
          id="email"
          type="email"
          placeholder="Η ηλεκτρονική σας διεύθυνση..."
          name="user_email"
        />
        <small className="email-error"></small>
      </div>

      <div className="input3">
        <label htmlFor="number">Κινητό Τηλέφωνο</label>
        <input
          id="number"
          type="text"
          placeholder="Το κινητό σας τηλέφωνο..."
          name="user_number"
        ></input>
        <small className="phoneNumber-error"></small>
      </div>

      <div className="input4">
        <label htmlFor="message">Μύνημα</label>
        <textarea id="message" placeholder="Το μύνημά σας..." name="message" />
        <small className="message-error"></small>
      </div>

      <input id="submitBtn" type="submit" value="Αποστολή" />
    </form>
  );
};

export default ContactForm;
