import React from "react";
import "../styling/components/navbar.css";
import eye_icon from "../images/eye_icon.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function ScrollToTop() {
  window.scrollTop(0);
}

const Navbar = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={eye_icon} height="44px" width="44px" alt=""></img>
        </Link>
        <div className="logo-name">
          <Link to="/">Δημήτριος Τριγκούδης</Link>
          <p>Χειρουργός Οφθαλμίατρος</p>
        </div>
      </div>
      <nav className="navbar">
        <header className="title">Menu</header>
        <ul>
          <li className="li-content">
            <Link className="dropdown-btn" onClick={ScrollToTop} to="/bio">
              ΒΙΟΓΡΑΦΙΚΟ
            </Link>
          </li>
          <li className="li-content">
            <Link className="dropdown-btn" onClick={ScrollToTop} to="/office">
              ΙΑΤΡΕΙΟ & ΕΞΟΠΛΙΣΜΟΣ
            </Link>
          </li>
          <li className="li-content">
            <Link className="dropdown-btn" onClick={ScrollToTop} to="/articles">
              ΑΡΘΡΑ
            </Link>
          </li>
          <li className="li-content">
            <HashLink to="/#contact">ΕΠΙΚΟΙΝΩΝΙΑ</HashLink>
          </li>
        </ul>
      </nav>
      <div className="menu-btn">
        <div className="menu-btn__burger"></div>
      </div>
    </header>
  );
};

// // ===================== JS CODE FOR NAVBAR =====================
var mSeconds = 1000;

setTimeout(function () {
  const menuBtn = document.querySelector(".menu-btn");
  const navbar = document.querySelector(".navbar");
  const container = document.querySelector(".container");

  // // //  HAMBURGER MENU
  menuBtn?.addEventListener("click", () => {
    menuBtn.classList.toggle("open");
    navbar.classList.toggle("active");
  });

  const elements = document.querySelectorAll(".dropdown-btn");
  const iconElement = document.querySelectorAll(".fa");

  document.querySelectorAll(".dropdown").forEach((e) => {
    e.addEventListener("click", (event) => {
      // console.log(event.target)
      if (elements[0] === event.target || iconElement[0] === event.target) {
        return event.target.parentNode.classList.toggle("open");
      } else if (
        elements[1] === event.target ||
        iconElement[1] === event.target
      ) {
        return event.target.parentNode.classList.toggle("open");
      }
    });
  });
  // // CLOSE THE MOBILE NAVBAR WHEN THE USER CLICKS ON A SPECIFIC NAVBAR ELEMENT
  const NavElem = document.querySelector(".li-content");

  NavElem?.addEventListener("click", () => {
    menuBtn.classList.toggle("open");
    navbar.classList.toggle("active");
  });

  if (navbar?.classList.contains("active")) {
    navbar.style.height = "100vh";
  } else {
    container.style.height = "12vh";
  }
}, mSeconds);

export default Navbar;
