import React from "react";

import "../styling/pages/biographyPage.css";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import bio_image from "../images/bio_image.png";

function ScrollToTop() {
  window.scrollTop(0);
}

const BiographyPage = () => {
  return (
    <div>
      <Navbar />
      <section className="biography-page">
        <div className="header-and-img">
          <header>Το βιογραφικό του Δημήτριου Τριγκούδη</header>
          <img  src={bio_image} alt=""></img>
        </div>
        <ul className="bio-text-container">
          <i class="fa-solid fa-arrow-right"></i>
          <li>
            <div className="list-design"></div>
            <p>
              Γεννήθηκα το 1967 σε ένα χωριό του νομού Έβρου, της περιφέρειας
              Διδυμοτείχου, στους Μεταξάδες, όπου και πέρασα τα παιδικά μου
              χρόνια.
            </p>
          </li>

          <li>
            <div className="list-design"></div>
            <p>
              Εισάχθηκα στο Αριστοτέλειο πανεπιστήμιο το 1985 και αποφοίτησα το
              1992 με βαθμό πτυχίου Λίαν Καλώς.
            </p>
          </li>

          <li>
            <div className="list-design"></div>
            <p>
              Εκπλήρωσα τη στρατιωτική μου θητεία από το 1992-1993 στο Σπήλαιο
              του νομού Έβρου.
            </p>
          </li>

          <li>
            <div className="list-design"></div>
            <p>
              Τοποθετήθηκα για την υπηρεσία υπαίθρου στο αγροτικό ιατρείο της
              Κερκίνης από το 1994 ως το 1995, με εφημερίες στο ΚΥ Ροδόπολης.
            </p>
          </li>

          <li>
            <div className="list-design"></div>
            <p>
              Άρχισα την εξειδίκευση μου στην Οφθαλμολογία το 1996 στο
              νοσοκομείο Κορίνθου με εφημερίες στον χειρουργικό τομέα, όπου και
              έμεινα ως το 1999. Συνέχισα και ολοκλήρωσα την ειδικότητα μου στο
              Ιπποκράτειο νοσοκομείο Θεσσαλονίκης το 2003.
            </p>
          </li>

          <li>
            <div className="list-design"></div>
            <p>
              Από το 2003 λειτουργώ ιδιωτικό ιατρείο, ενώ υπήρξα και εργαζόμενος
              στο ΙΚΑ έως το 2012.
            </p>
          </li>

          <li>
            <div className="list-design"></div>
            <p>
              Είμαι παντρεμένος με την παιδίατρο Διάφα Κωνσταντίνα και έχω 3
              παιδιά.
            </p>
          </li>
        </ul>
      </section>
      <Footer />
    </div>
  );
};

export default BiographyPage;
