import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import OfficeImageSlider from "../components/OfficeImageSlider";

import office_google_maps_img from "../images/google-maps-office.jpg";

import "../styling/pages/officePage.css";
// import slider_img from "../images/doctor-office.jpg";
// import slider_img2 from "../images/1.jpg";
// import slider_img3 from "../images/2.jpg";
// import slider_img4 from "../images/eye-illness.jpg";
// import slider_img5 from "../images/background-img.png";
// import slider_img5 from '../images/background-image.jpg';
import slider_img6 from "../images/office_1.jpg";
import slider_img7 from "../images/office_2.jpg";
import slider_img8 from "../images/office_3.jpg";
import slider_img9 from "../images/office_4.jpg";
import slider_img10 from "../images/office_5.jpg";
import slider_img11 from "../images/office_6.jpg";

import mach1 from '../images/machine_image1.jpg';
import mach2 from '../images/machine_image2.jpg';
import mach3 from '../images/machine_image3.jpg';
import mach4 from '../images/machine_image4.jpg';
import mach5 from '../images/machine_image5.jpg';
import mach6 from '../images/machine_image6.jpg';
import mach7 from '../images/machine_image7.jpg';
import mach8 from '../images/machine_image8.jpg';
import mach9 from '../images/machine_image9.jpg';
import mach10 from '../images/machine_image10.jpg';
import mach11 from '../images/machine_image11.jpg';
import mach12 from '../images/machine_image12.jpg';
import mach13 from '../images/machine_image13.jpg';
import mach14 from '../images/machine_image14.jpg';
import mach15 from '../images/machine_image15.jpg';


const slides = [
  // slider_img,
  // slider_img2,
  // slider_img3,
  // slider_img4,
  // slider_img5,
  slider_img6,
  slider_img7,
  slider_img8,
  slider_img9,
  slider_img10,
  slider_img11,
];


const OfficePage = () => {
  return (
    <div>
      <Navbar />
      <section className="office-page">
        <div className="text-background">
          <div className="text-container">
            <header>Το Ιατρείο </header>
            <p>
            Tο ιατρείο του Δημήτριου Τριγκούδη βρίσκεται στην Θεσσαλονίκη στην Οδό Λεωφόρος Στρατού 9, στον 5ο όροφο. Είναι πλήρως εξοπλισμένο με μηχανήματα τελευταίας τεχνολογίας και με ό,τι χρειάζεται για μία σωστή και ολοκληρωμένη οφθαλμολογική εξέταση και διάγνωση. Τα κυριότερα από αυτά αναφέρονται παρακάτω: 
            </p>
          </div>
        </div>

        <div className="medical-equipment-section">
          <header className="med-equip-header">
            Ιατρικός Εξοπλισμός Ιατρείου
          </header>

          <div className="machine-sample">
            <header>Διαθλασίμετρο Οίκου NIDEK</header>
            <p>
            Χρησιμοποιείται για τον έλεγχο διαθλαστικών ανωμαλιών. Μας δίνει μια αυτόματη μέτρηση της μυωπικής, υπερμετρωπικής ή αστιγματικής ανωμαλίας του εξεταζόμενου. Συνδέεται αυτόματα με το φερόπτερο για μεταφορά δεδομένων.
            </p>
            <img src={mach1}></img>
          </div>

          <div className="machine-sample">
            <header>Φορόπτερο Οίκου ΝIDEK</header>
            <img className="special-img" src={mach2}></img>
            <p>
            Χρησιμοποιείται για τον έλεγχο της διάθλασης του εξεταζόμενου και της διόρθωσης. Διαθέτει προηγμένες λειτουργίες διόφθαλμου ελέγχου καθώς και βασικά τεστ (Wroth test, Contrast Sensitivity, στερεοσκοπίας κλπ.)
            </p>
          </div>
          <div className="machine-sample">
            <header>Αυτόματο Οπτότυπο Οίκου NIDEK</header>
            <img src={mach3}></img>
            <p>
            Εξελιγμένος πίνακας ελέγχου της διάθλασης, που αντικαθιστά τον παλιό πίνακα του προβολέα με εμφάνιση διαφόρων τεστ με αριθμούς, γράμματα, σχήματα για παιδιά καθώς και άλλα τεστ. Πλήρως διασυνδεμένος με το φορόπτερο και τον κεντρικό έλεγχο.
            </p>
          </div>
          <div className="machine-sample">
            <header>Φακόμετρο Οίκου NIDEK</header>
            <img src={mach4}></img>
            <p>
            Αυτόματη μέτρηση των γυαλιών. Διαθέτει τεχνολογία μέτρησης UV Protection. Συνδέεται με το φορόπτερο για απευθείας μετάδοση των δεδομένων.
            </p>
          </div>
          <div className="machine-sample">
            <header>Φακόμετρο Οίκου HUVITZ</header>
            <img src={mach5}></img>
            <p>
            Αυτόματη μέτρηση των γυαλιών όπως και το φακόμετρο Nidek.
            </p>
          </div>
          <div className="machine-sample">
            <header>Κεντρική Κονσόλα Ελέγχου</header>
            <img className="special-img" src={mach6}></img>
            <p>
            Αποτελεί το κεντρικό εργαλείο ελέγχου και διασύνδεσης λοιπών εξεταστικών μηχανημάτων της ίδιας εταιρείας. Αυτοματοποιεί την μεταφορά των δεδομένων. Δίνει δυνατότητα εύκολης σύγκρισης μεταξύ υπαρχόντων γυαλιών και προτεινόμενων καινούριων χωρίς αλλαγή σκελετών.
            </p>
          </div>
          <div className="machine-sample">
            <header>Αυτόματο Παιδικό Διαθλασίμετρο Οίκου PLUSOPTIX</header>
            <img src={mach7}></img>
            <p>
            Πλήρως αυτοματοποιημένο μηχάνημα για έλεγχο διαθλαστικών ανωμαλιών σε παιδιά και σε μη συνεργαζόμενους ασθενείς. Μας δίνει αξιόπιστες μετρήσεις βαθμών διαθλαστικής ανωμαλίας, καθώς επίσης και έλεγχο στραβισμού και διαύγειας των οφθαλμών.
            </p>
          </div>
          <div className="machine-sample">
            <header>Τοπογραφία Οίκου TOMEY</header>
            <img src={mach8}></img>
            <p>
            Αυτόματη τοπογραφία κερατοειδούς. Απαραίτητη εξέταση για διάγνωση και έλεγχο εξέλιξης του κερατόκωνου, καθώς και άλλων ανωμαλιών του κερατοειδούς. Επίσης χρησιμοποιείται για την εφαρμογή φακών επαφής.
            </p>
          </div>
          <div className="machine-sample">
            <header>Αυτόματο Τονόμετρο Αέρος Οίκου KEELER</header>
            <img src={mach9}></img>
            <p>
            Με το μηχάνημα αυτό μετράμε την πίεση του ματιού αυτόματα χωρίς σταγόνες αναισθησίας και χωρίς επαφή στον κερατοειδή. Απλώς φυσάει λίγο αέρα στο μάτι. Είναι δυνατή η μέτρηση ακόμα και σε παιδιά. Οι μετρήσεις είναι πλήρως αξιόπιστες. 


            </p>
          </div>
          <div className="machine-sample">
            <header>Οπτική Τομογραφία Συνοχής (OCT) Οίκου NIDEK</header>
            <img className="special-img" src={mach10}></img>
            <p>
            Πρόκειται για μηχάνημα τελευταίας τεχνολογίας που μας δίνει αξιόπιστες πληροφορίες για τα οπίσθια και τα πρόσθια μέρη του οφθαλμού. Ουσιαστικά πρόκειται για μια τύπου αξονική τομογραφία των δομών του βολβού. Απαραίτητη εξέταση για παθήσεις της ωχράς, του γλαυκώματος καθώς και πολλών άλλων.
            </p>
          </div>
          <div className="machine-sample">
            <header>Αυτόματο Ψηφιακό Περίμετρο Οίκου TOMEY</header>
            <img src={mach11}></img>
            <p>
            Πλήρως αυτοματοποιημένο περίμετρο για έλεγχο οπτικών πεδίων. Δυνατότητα ελέγχου της κεντρικής περιοχής όπως όλα τα σύγχρονα περίμετρα, αλλά και με δυνατότητα ελέγχου έως και 90 μοίρες. Είναι η βασική εξέταση που γίνεται για την διάγνωση και παρακολούθηση των γλαυκωματικών παθήσεων. Επίσης ιδιαίτερα χρήσιμη για νευρολογικές παθήσεις (αδένωμα υπόφυσης, κλπ.)
            </p>
          </div>
          <div className="machine-sample">
            <header>A-SCAN Οίκου ACCUSONIC</header>
            <img className="special-img" src={mach12}></img>
            <p>
            Χρησιμοποιείται για να βρούμε το αξονικό κυρίως μήκος του ματιού. Η μέτρηση αυτή είναι απαραίτητη για τη δύναμη του ενδοφακού στην επέμβαση του καταρράκτη.
            </p>
          </div>
          <div className="machine-sample">
            <header>Παχυμετρία Κερατοειδούς Οίκου TOMEY</header>
            <img src={mach13}></img>
            <p>
            Το μηχάνημα μετράει το πάχος του κερατοειδούς. Πρόκειται για εξέταση απαραίτητη για τη μελέτη του γλαυκώματος καθώς και για διαθλαστικές επεμβάσεις ή άλλες παθολογικές καταστάσεις του κερατοειδή
            </p>
          </div>
          <div className="machine-sample">
            <header>Camera Βυθού και Πρόσθιων Μορίων Οίκου OPTOMED</header>
            <img src={mach14}></img>
            <p>
            Πρόκειται για εξελιγμένη φορητή φωτογραφική μηχανή που μπορεί να αποτυπώσει την εικόνα του αμφιβληστροειδούς αλλά και των προσθίων μορίων του βολβού.
            </p>
          </div>
          <div className="machine-sample">
            <header>Διαθερμία Βλεφαρίδων</header>
            <img className="special-img" src={mach15}></img>
            <p>
            Πρόκειται για εξειδικευμένο μηχάνημα καυτηρίασης της ρίζας των βλεφαρίδων. Ιδιαίτερα χρήσιμο για περιπτώσεις τριχίασης (στροφή των βλεφαρίδων προς τον βολβό)
            </p>
          </div>
        </div>

        <div className="sliderContainerStyles">
          <OfficeImageSlider slides={slides} />
        </div>

        <div className="google-maps-img">
          <h1>Η τοποθεσία του ιατρείου στον χάρτη</h1>
          <a
            href="https://www.google.com/maps/place/%CE%9B%CE%B5%CF%89%CF%86.+%CE%A3%CF%84%CF%81%CE%B1%CF%84%CE%BF%CF%8D+9,+%CE%98%CE%B5%CF%83%CF%83%CE%B1%CE%BB%CE%BF%CE%BD%CE%AF%CE%BA%CE%B7+546+39/@40.6211242,22.9552073,17z/data=!3m1!4b1!4m5!3m4!1s0x14a838e317c53175:0xff82a09467bc7962!8m2!3d40.6211201!4d22.957396"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={office_google_maps_img}
              width="70%"
              alt="Office-Map-Location"
            ></img>
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default OfficePage;
