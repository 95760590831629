import React from "react";
import "../styling/components/footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <section className="footer">
      <h1 className="footer-header">
        Φροντίδα μας......<span>τα μάτια σας.</span>
      </h1>
      <div className="footer-box">
        <div className="name-job">
          <h1>Δημήτριος Τριγκούδης</h1>
          <p>Χειρουργός Οφθαλμίατρος</p>
        </div>
        <div className="contacts">
          <div className="phone-mobile-container">
            <div className="icon-text-container" id="phone-container">
              <i>
                <FontAwesomeIcon icon={faPhone} size="2x" id="phone-icon" />
              </i>
              <p id="phone">
                (<span>+30</span>)2310886470
              </p>
            </div>

            <div className="icon-text-container" id="mobile-container">
              <i>
                <FontAwesomeIcon
                  icon={faMobileScreenButton}
                  size="2x"
                  id="mobile-icon"
                />
              </i>
              <p id="mobile-phone">
                (<span>+30</span>)6979773031
              </p>
            </div>
          </div>

          <div className="icon-text-container" id="email-container">
            <i>
              <FontAwesomeIcon icon={faEnvelope} size="2x" id="email-icon" />
            </i>
            <p id="email">trg1967@gmail.com</p>
          </div>
        </div>

        <h2>Λεωφ. Στρατού 9, έναντι Γαλλικού Ινστιτούτου, Θεσσαλονίκη</h2>

        <div className="rights">
          <p>Dimitrios Trigkoudis 2023&copy; all rights reserved</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
